/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReactHTMLParser from 'react-html-parser';
import { useSelector } from 'react-redux';

import {
    array, bool, func, number,
} from 'prop-types';
import ProductImage from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/ProductImage';
import PriceRange from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/PriceRange';
import determineProductUrl from '../../../../../helpers/determineProductUrl';
import noop from '../../../../../helpers/noop';
import { getPriceRangeLayout } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const useStyles = makeStyles((theme) => ({
    container: {
        flex: '1 1 50%',
    },
    productContainer: ({ showFullGrid }) => ({
        display: 'grid',
        gap: '24px 16px',
        gridTemplateColumns: showFullGrid ? 'repeat(6, 1fr)' : 'repeat(3, 1fr)',
        [theme.breakpoints.down(600)]: {
            gap: '16px',
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    }),
    product: {
        position: 'relative',
        '& img': {
            width: '100%',
            position: 'static',
        },
    },
    priceContainer: {
        position: 'absolute',
        backgroundColor: 'white',
        bottom: '10px',
        left: '10px',
        padding: '2px 4px',
        borderRadius: 2,
        textTransform: 'capitalize',
        fontFamily: 'Lato',
        color: 'black',
        '& span': {
            fontWeight: '700',
            fontSize: '14px',
        },
    },
    buttonContainer: {
        paddingTop: '32px',
    },
    button: {
        background: '#fff',
        padding: '7px 20px',
        border: 'none',
        fontSize: '16px',
        display: 'flex',
        fontFamily: 'Lato',
        alignItems: 'center',
        color: '#000',
        cursor: 'pointer',
        fontWeight: '700',
    },
}));

const ProductUI = ({
    slicedProducts, minRowsToShow, showFullGrid,
    isExpanded, handleSeeMore, handleSeeLess, maxRowsAllowed, handleLinkOnClick,
}) => {
    const classes = useStyles({ showFullGrid });
    const priceRangeLayout = useSelector(getPriceRangeLayout);
    const priceRangeLayoutFromCMS = priceRangeLayout && priceRangeLayout?.length > 0 ? priceRangeLayout : [{ viewport: 'Mobile', price_layout: 'from $xxx.xx' }, { viewport: 'Desktop', price_layout: 'from $xxx.xx' }];

    if (slicedProducts?.length > 0) {
        return (
            <div className={classes.container}>
                <div className={classes.productContainer}>
                    {slicedProducts.map((product) => {
                        const {
                            name, image, skuPriceRange, partNumber, seo,
                        } = product;
                        return (
                            <Link
                                data-testid={`${name}${'-'}${partNumber}`?.split(' ')?.join('-')?.toLowerCase()}
                                data-test="recommended-product"
                                title={`${ReactHTMLParser(name)}`}
                                tabIndex="0"
                                to={determineProductUrl(seo?.url)}
                                onClick={() => handleLinkOnClick(partNumber)}
                                className={classes.product}
                                key={name}
                            >
                                <ProductImage image={image} name={name} />
                                <div className={classes.priceContainer}>
                                    <PriceRange
                                        skuPriceRange={skuPriceRange}
                                        priceRangeLayout={priceRangeLayoutFromCMS}
                                    />
                                </div>
                            </Link>
                        );
                    })}
                </div>

                {
                    (minRowsToShow < maxRowsAllowed) && (
                        <div className={classes.buttonContainer}>
                            <button
                                className={classes.button}
                                onClick={isExpanded ? handleSeeLess : handleSeeMore}
                                type="button"
                            >
                                {isExpanded ? 'See Less' : 'See More'}
                            </button>
                        </div>
                    )
                }
            </div>
        );
    }

    return null;
};

ProductUI.propTypes = {
    slicedProducts: array,
    minRowsToShow: number,
    showFullGrid: bool,
    isExpanded: bool,
    handleSeeMore: func,
    handleSeeLess: func,
    handleLinkOnClick: func,
    maxRowsAllowed: number.isRequired,
};

ProductUI.defaultProps = {
    slicedProducts: [],
    minRowsToShow: 1,
    showFullGrid: false,
    isExpanded: false,
    handleSeeMore: noop,
    handleSeeLess: noop,
    handleLinkOnClick: noop,
};

export default ProductUI;
