/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
// libraries
import React from 'react';
import { connect } from 'react-redux';
import { object, string, bool } from 'prop-types';
// containers
// eslint-disable-next-line import/no-cycle
import ContentComponentContainer from '../containers/ContentComponentContainer';
import { getFeatureFlags } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getBrand } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getIsBot } from '../../../../../state/ducks/App/App-Selectors';
import { getActiveABTests } from '../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';

export const ABTesting = ({
    featureFlags,
    block,
    brand,
    ssrDeviceType,
    presentationFamily,
    isBot,
    activeABTests,
}) => {
    const { simple_component_name } = block;
    const optimizeIsLoading = !activeABTests?.loaded && !activeABTests?.timeout;

    if (featureFlags['is-homepage-ab-block-enabled'] && (block.is_optimize_test)) {
        if (activeABTests[simple_component_name] || !optimizeIsLoading) {
            const variant = activeABTests[simple_component_name] || 'CONTROL';
            return (
                <ContentComponentContainer
                    block={block}
                    variant={isBot ? 'CONTROL' : variant}
                    brand={brand.domain}
                    ssrDeviceType={ssrDeviceType}
                    presentationFamily={presentationFamily}
                    isOptimizeTest
                />
            );
        }
        return (
            <div style={{
                width: block?.skeleton?.width, height: block?.skeleton?.height, background: '#e3e3e3',
            }}
            />
        );
    }

    // TODO as more A/B tests start this can be converted to some type of functional lookup to handle multivariants
    // This will sync with the findContent query in the ContentComponent
    // flow is check if a a/b test is enabled (true/false) [ needed because we want to protect rendering the ab block in certain envs or brands ]
    if (featureFlags['is-homepage-ab-block-enabled'] && featureFlags[block.feature_flag_name]) {
        // extract relevant variant for flag [ flag is set in CMS ab_testing_block]
        const { variant } = featureFlags[block.feature_flag_name] || 'CONTROL';
        return (
            <ContentComponentContainer
                block={block}
                variant={isBot ? 'CONTROL' : variant}
                brand={brand.domain}
                ssrDeviceType={ssrDeviceType}
                presentationFamily={presentationFamily}
                isOptimizeTest={false}
            />
        );
    }
    return null;
};

ABTesting.propTypes = {
    featureFlags: object.isRequired,
    brand: object.isRequired,
    block: object.isRequired,
    ssrDeviceType: string.isRequired,
    presentationFamily: string.isRequired,
    isBot: bool.isRequired,
    activeABTests: object.isRequired,
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    brand: getBrand(state),
    isBot: getIsBot(state),
    activeABTests: getActiveABTests(state),
});

export default connect(mapStateToProps)(ABTesting);
