/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    bool,
    func,
    number, object,
} from 'prop-types';

import ProductUI from './ProductUI';
import noop from '../../../../../helpers/noop';

const DesktopUIContainer = ({
    sfData, minRowsToShow, showRecentlyViewed, trackClickThrough,
}) => {
    const [rowCount, setRowCount] = useState(minRowsToShow);
    const [rowCount1, setRowCount1] = useState(minRowsToShow);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpanded1, setIsExpanded1] = useState(false);

    const productRecs = sfData?.recentlyViewedRecs?.productRecs;
    const recentProducts = sfData?.recentlyViewedRecs?.products;

    const PRODUCT_TO_SHOW_PER_ROW = showRecentlyViewed ? 3 : 6;
    const PRODUCT_TO_SHOW_PER_ROW1 = productRecs?.length ? 3 : 6;

    const totalProductsRows = Math.ceil(productRecs?.length > 0 ? ((productRecs?.length) / PRODUCT_TO_SHOW_PER_ROW) : 1);
    const totalProductsRows1 = Math.ceil(recentProducts?.length > 0 ? ((recentProducts?.length) / PRODUCT_TO_SHOW_PER_ROW1) : 1);

    const maxRowsAllowed = Math.min(totalProductsRows, showRecentlyViewed ? 4 : 2);
    const maxRowsAllowed1 = Math.min(totalProductsRows1, productRecs?.length ? 4 : 2);

    const handleSeeMore = () => {
        const newRowCount = rowCount + 1;
        if (newRowCount >= maxRowsAllowed) {
            setIsExpanded(true);
        }
        setRowCount(newRowCount);

        const newRowCount1 = rowCount1 + 1;
        if (newRowCount1 >= maxRowsAllowed1) {
            setIsExpanded1(true);
        }
        setRowCount1(newRowCount1);
    };

    const handleSeeLess = () => {
        setIsExpanded(false);
        setRowCount(minRowsToShow);
        setIsExpanded1(false);
        setRowCount1(minRowsToShow);
    };

    const maxProductsToShow = Math.min(rowCount, maxRowsAllowed) * PRODUCT_TO_SHOW_PER_ROW;
    const maxProductsToShow1 = Math.min(rowCount1, maxRowsAllowed1) * PRODUCT_TO_SHOW_PER_ROW1;

    return (
        <>
            {
                showRecentlyViewed && (
                    <ProductUI
                        slicedProducts={recentProducts?.slice(0, maxProductsToShow1)}
                        maxRowsAllowed={maxRowsAllowed1}
                        minRowsToShow={minRowsToShow}
                        handleSeeLess={handleSeeLess}
                        handleSeeMore={handleSeeMore}
                        handleLinkOnClick={trackClickThrough}
                        isExpanded={isExpanded1}
                        showFullGrid={PRODUCT_TO_SHOW_PER_ROW1 === 6}
                    />
                )
            }
            <ProductUI
                slicedProducts={productRecs?.slice(0, maxProductsToShow)}
                maxRowsAllowed={maxRowsAllowed}
                minRowsToShow={minRowsToShow}
                handleSeeLess={handleSeeLess}
                handleSeeMore={handleSeeMore}
                handleLinkOnClick={trackClickThrough}
                isExpanded={isExpanded}
                showFullGrid={PRODUCT_TO_SHOW_PER_ROW === 6}
            />
        </>

    );
};

DesktopUIContainer.propTypes = {
    sfData: object.isRequired,
    minRowsToShow: number,
    showRecentlyViewed: bool,
    trackClickThrough: func,
};

DesktopUIContainer.defaultProps = {
    minRowsToShow: 1,
    showRecentlyViewed: false,
    trackClickThrough: noop,
};

export default DesktopUIContainer;
