/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { array } from 'prop-types';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import HeroSingleResponsive from './HeroSingleResponsive';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

const HeroSingleResponsiveAbTestContainer = ({ data }) => {
    const ffIsHeroTestEnabled = useSelector(getFeatureFlag('is-hero-test-enabled'));

    let ldVariant;
    // CSR only
    if (typeof window !== 'undefined') {
        const ldClient = useLDClient();
        ldVariant = ldClient?.variation('which-hero-test')?.variant?.toLowerCase();
    }
    // SSR skeleton when test is enabled
    // ldVariant will be undefined(SSR) and 'string'(CSR) reason for negation
    if (ffIsHeroTestEnabled && !ldVariant) {
        return (
            <div style={{
                height: `${data[0].min_height}px` || '300px',
                width: '100%',
                background: 'linear-gradient(270deg, #E7EDF1, #F2F8FC, #E7EDF1)',
            }}
            />
        );
    }

    // loop through reference array find the ldVariant that matches the cmsVariant
    for (let i = 0; i < data.length; i += 1) {
        const cmsVariant = data?.[i].a_b_testing?.variant?.toLowerCase();
        if (ldVariant === cmsVariant) {
            return <HeroSingleResponsive data={data[i]} />;
        }
    }
    return null;
};

HeroSingleResponsiveAbTestContainer.propTypes = {
    data: array.isRequired,
};

export default HeroSingleResponsiveAbTestContainer;
