/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { bool, object } from 'prop-types';

import Heading from './Heading';
import MobileUIContainer from './MobileUIContainer';
import DesktopUIContainer from './DesktopUIContainer';
import useClickStreamCustomEventsTracking from '../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import MobileCarousel from './MobileCarousel';

const useStyles = makeStyles((theme) => ({
    main: (styles) => ({
        backgroundColor: styles?.backgroundColor || '#FFF8F0',
        width: '100%',
        maxWidth: '1400px',
        margin: '0 auto',
        padding: '32px 0px',
        [theme.breakpoints.down(600)]: {
            padding: '24px 0px 32px',
            marginBottom: '32px',
        },
    }),
    root: {
        maxWidth: '1440px',
        margin: '0 auto',
        padding: '0 80px',
        display: 'flex',
        gap: '48px',
        [theme.breakpoints.down(600)]: {
            padding: '0 16px',
        },
    },
    newDesign: {
        paddingRight: '0',
    },
}));

export default function RecentlyViewedGridUI({ sfData, cmsData, isNewDesign }) {
    const isMobile = useMediaQuery('(max-width: 600px)');

    const {
        minimum_rows_to_show,
        min_products_to_show,
        title_font_family: fontFamily,
        title_font_size: fontSize,
        title_font_style: fontStyle,
        title_font_color,
        background_color,
    } = cmsData || {};

    const classes = useStyles({ backgroundColor: background_color?.color });

    const title = sfData?.recentlyViewedRecs?.campaign?.campaignResponses[0]?.payload?.productRecTitle;
    const productName = sfData?.recentlyViewedRecs?.campaign?.campaignResponses[0]?.payload?.productName;

    // Recently viewed title and subtitle
    const recentHeading = sfData?.recentlyViewedRecs?.productsContent?.personalized_carousel?.heading[0] || {};
    const recentSubHeading = sfData?.recentlyViewedRecs?.productsContent?.personalized_carousel?.heading[1] || {};
    const customStyles = {
        fontFamily: recentHeading?.font_family,
        fontSize: recentHeading?.font_size,
        fontStyle: recentHeading?.font_style,
        color: recentHeading?.color?.color,
        fontFamily1: recentSubHeading?.font_family,
        fontSize1: recentSubHeading?.font_size,
        fontStyle1: recentSubHeading?.font_style,
        color1: recentSubHeading?.color?.color,
    };

    const showRecentlyViewed = sfData?.recentlyViewedRecs?.products?.length >= min_products_to_show;

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: sfData?.recentlyViewedRecs,
        isFireImpression: false, // don't fire impression from here.
        page: { type: 'home' },
    });

    if (isMobile) {
        return (
            <>
                {
                    showRecentlyViewed && (
                        <div className={classes.main}>
                            <div className={classes.root}>
                                <Heading
                                    title={recentHeading?.copy || 'Recently Viewed Products'}
                                    subTitle={recentSubHeading?.copy || 'These gifts miss you. Take another look!'}
                                    styles={customStyles}
                                />
                            </div>
                            <div className={`${classes.root} ${isNewDesign ? classes.newDesign : ''}`}>
                                {isNewDesign
                                    ? <MobileCarousel products={sfData?.recentlyViewedRecs?.products} handleLinkOnClick={trackClickThrough} />
                                    : <MobileUIContainer products={sfData?.recentlyViewedRecs?.products} minRowsToShow={minimum_rows_to_show || 1} trackClickThrough={trackClickThrough} />}
                            </div>
                        </div>
                    )
                }
                {sfData?.recentlyViewedRecs?.productRecs?.length > 0 && (
                    <div className={classes.main}>
                        <div className={classes.root}>
                            <Heading
                                title={title || 'Because you viewed...'}
                                subTitle={productName}
                                styles={{
                                    fontFamily, fontSize, fontStyle, color: title_font_color?.color,
                                }}
                            />
                        </div>
                        <div className={`${classes.root} ${isNewDesign ? classes.newDesign : ''}`}>
                            {isNewDesign
                                ? <MobileCarousel products={sfData?.recentlyViewedRecs?.productRecs} handleLinkOnClick={trackClickThrough} />
                                : <MobileUIContainer products={sfData?.recentlyViewedRecs?.productRecs} minRowsToShow={minimum_rows_to_show || 1} trackClickThrough={trackClickThrough} />}
                        </div>
                    </div>
                )}
            </>
        );
    }

    if (sfData?.recentlyViewedRecs?.productRecs?.length > 0 || showRecentlyViewed) {
        return (
            <div className={classes.main}>
                <div className={classes.root}>
                    {showRecentlyViewed && (
                        <Heading
                            title={recentHeading?.copy || 'Recently Viewed Products'}
                            subTitle={recentSubHeading?.copy || 'These gifts miss you. Take another look!'}
                            styles={customStyles}
                        />
                    )}
                    {sfData?.recentlyViewedRecs?.productRecs?.length > 0 && (
                        <Heading
                            title={title || 'Because you viewed...'}
                            subTitle={productName}
                            styles={{
                                fontFamily, fontSize, fontStyle, color: title_font_color?.color,
                            }}
                        />
                    )}
                </div>
                <div className={classes.root}>
                    <DesktopUIContainer sfData={sfData} minRowsToShow={minimum_rows_to_show || 1} showRecentlyViewed={showRecentlyViewed} trackClickThrough={trackClickThrough} />
                </div>
            </div>
        );
    }

    return null;
}

RecentlyViewedGridUI.propTypes = {
    sfData: object,
    cmsData: object,
    isNewDesign: bool,
};

RecentlyViewedGridUI.defaultProps = {
    sfData: {},
    cmsData: {},
    isNewDesign: false,
};
