/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, shape, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import customBreakpoints from '../../../../../helpers/customBreakpoints';
import ResponsiveImage from '../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';

const styles = (theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '30px 0',
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
            display: 'none',
        },
    },
    banner: {
        margin: '0 auto',
        width: '100%',
        maxWidth: '100%',
    },
});

// TODO:
//  - Add mobile
const CeoBannerUI = ({
    classes,
    data: {
        desktop_image: {
            url,
            title,
        },
    },
}) => (
    <div className={classes.root}>
        <ResponsiveImage
            className={classes.banner}
            path={url}
            alt={title.split('.')[0]}
            params={{
                desktop: 'width=1680&height=128',
                tablet: 'width=1078&height=82quality=100',
                mobile: 'width=200&height=200',
            }}
            breakpoints={{
                desktop: '1024px',
                tablet: '640px',
                mobile: '200px',
            }}
        />
    </div>
);

CeoBannerUI.propTypes = {
    classes: object.isRequired,
    data: shape({
        desktop_image: shape({
            url: string.isRequired,
            title: string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default withStyles(styles)(CeoBannerUI);
