/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const isVideoFormat = (value) => {
    const formats = ['video/mp4', 'video/ogg', 'video/avi', 'video/webm', 'video/mov', 'video/mkv', 'video/wmv', 'video/avchd'];
    return formats.includes(value);
};

export default isVideoFormat;
