/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReactHTMLParser from 'react-html-parser';
import { useSelector  } from 'react-redux';

import { array, func } from 'prop-types';
import determineProductUrl from '../../../../../helpers/determineProductUrl';
import ProductImage from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/ProductImage';
import PriceRange from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/PriceRange';
import noop from '../../../../../helpers/noop';
import { getPriceRangeLayout } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const useStyles = makeStyles((theme) => ({
    productContainer: {
        display: 'flex',
        gap: '16px',
        overflowX: 'auto',
        scrollSnapType: 'x mandatory',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    product: {
        flex: '0 0 34%', // Adjust width for 2.5 items in view
        position: 'relative',
        scrollSnapAlign: 'start',
        '& img': {
            width: '100%',
            position: 'static',
        },
        '&:last-child': {
            marginRight: '16px',
        },
    },
    priceContainer: {
        position: 'absolute',
        backgroundColor: 'white',
        bottom: '10px',
        left: '10px',
        padding: '2px 4px',
        borderRadius: 2,
        textTransform: 'capitalize',
        fontFamily: 'Lato',
        color: 'black',
        '& span': {
            fontWeight: '700',
            fontSize: '14px',
        },
    },
}));

const MobileCarousel = ({ products, handleLinkOnClick }) => {
    const classes = useStyles();
    const priceRangeLayout = useSelector(getPriceRangeLayout);
    const priceRangeLayoutFromCMS = priceRangeLayout && priceRangeLayout?.length > 0 ? priceRangeLayout : [{ viewport: 'Mobile', price_layout: 'from $xxx.xx' }, { viewport: 'Desktop', price_layout: 'from $xxx.xx' }];

    return (
        <div className={classes.productContainer} data-testid="mobile-carousel">
            {products?.map((product) => {
                const {
                    name, image, skuPriceRange, partNumber, seo,
                } = product;
                return (
                    <Link
                        data-testid={`${name}${'-'}${partNumber}`?.split(' ')?.join('-')?.toLowerCase()}
                        data-test="recommended-product"
                        title={`${ReactHTMLParser(name)}`}
                        tabIndex="0"
                        to={determineProductUrl(seo?.url)}
                        onClick={() => handleLinkOnClick(partNumber)}
                        className={classes.product}
                        key={name}
                    >
                        <ProductImage image={image} name={name} />
                        <div className={classes.priceContainer}>
                            <PriceRange
                                skuPriceRange={skuPriceRange}
                                priceRangeLayout={priceRangeLayoutFromCMS}
                            />
                        </div>
                    </Link>
                );
            })}
        </div>
    );
};

MobileCarousel.propTypes = {
    products: array,
    handleLinkOnClick: func,
};

MobileCarousel.defaultProps = {
    products: [],
    handleLinkOnClick: noop,
};

export default MobileCarousel;
