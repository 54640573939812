/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import {
    string, bool, array, shape, arrayOf, number, object, func,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Panel2 from '../../../ContentTypeComponents/Panel';
import { ifExternalUrl } from '../../../ContentTypeComponents/NUp/helper';
import { getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getSSRDeviceType } from '../../../../../state/ducks/App/App-Selectors';
import ResponsiveImage from '../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import useClickStreamCustomEventsTracking from '../../../../helpers/Personalization/useClickstreamCustomEventsTracking';

const useStyles = makeStyles((theme) => ({
    root: ({
        blockMargin,
        presentation_type,
        height,
    }) => ({
        width: `${presentation_type?.indexOf('Fixed') !== -1 ? '1400px' : '100%'}`,
        maxWidth: `${presentation_type?.indexOf('Department') !== -1 ? '1920px' : '100%'}`,
        height: height ? `${height}px` : '400px',
        boxSizing: 'border-box',
        display: 'block',
        margin: blockMargin?.top && blockMargin?.bottom ? `${blockMargin?.top}px auto ${blockMargin?.bottom}px` : '0 auto',
        textDecoration: 'none',
        backgroundPosition: '50% center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: theme.palette.common?.black || '#000000', // TODO: Font color should come from CMS
        '@media screen and (max-width: 768px)': {
            width: '100% !important',
            backgroundPosition: 'center center',
        },
    }),
    contentSectionWrapper: ({ height }) => ({
        margin: '0 auto',
        height: height ? `${height}px` : '400px',
        zIndex: 2,
        position: 'relative',
    }),
    contentSectionWrapperCopyPosition: {
        overflow: 'hidden',
        margin: '0 auto',
        height: '100%',
        width: '100%',
        textAlign: 'center',
        position: 'relative',
    },
    contentSection: {
        margin: '0px auto',
        padding: '30px 0',
        textAlign: 'center',
        display: 'table',
    },
    contentSectionVideo: {
        display: 'table',
    },
    contentSectionCopyPosition: ({ copyPosition }) => ({
        width: '40%',
        position: 'relative',
        top: copyPosition?.top || '50%',
        right: copyPosition?.right || '50%',
        bottom: copyPosition?.bottom || '',
        left: copyPosition?.left || '',
        transform: 'translate(50%, -50%)',
        [theme.breakpoints.down(1024)]: {
            '& > div > div': {
                margin: '0 auto',
                fontSize: '0.8em !important',
            },
        },
        [theme.breakpoints.down(760)]: {
            '& span:first-of-type > div:first-of-type': {
                fontSize: '1.7em !important',
            },
            width: '25%',
        },
    }),
    tabletBanner: {
        '@media screen and (max-width: 960px)': {
            minHeight: '240px',
            height: '100% !important',
        },
    },
    objectFit: ({ height }) => ({
        objectFit: 'cover',
        height: height || 'auto',
        aspectRatio: '1.89/1',
    }),

    imageContainer: {
        position: 'relative',
    },
    top: {
        position: 'absolute',
        zIndex: 1,
    },
    bottom: {
        position: 'absolute',
    },
}));

const position = (message_location) => {
    if (message_location !== undefined && message_location !== null) {
        let margin = 'center';
        switch (message_location.toLowerCase()) {
            case 'left':
                margin = { margin: '0 auto 0 0' };
                break;
            case 'center':
                margin = { margin: '0 auto' };
                break;
            case 'right':
                margin = { margin: '0 0 0 auto' };
                break;
            case 'no margin':
                margin = { margin: '0' };
                break;
            default:
                margin = { margin: '0 auto' };
                break;
        }
        return margin;
    }
    return {};
};

const PersonalizedHero = ({ data, salesforceResponse, setShowSkeleton }) => {
    const presentationFamily = useSelector(getPresentationFamily);
    const ssrDeviceType = useSelector(getSSRDeviceType);
    const isMobile = ssrDeviceType === 'mobile';
    const {
        message_location,
        presentation_type,
        height,
        blockMargin,
        reference,
    } = data;

    const referenceData = reference?.[0];
    const {
        linking,
        message_collection,
        message_styling,
        image,
        image_mobile,
        overlays,
        container_attributes,
        uid = '',
    } = referenceData;

    const containerAttributePosition = container_attributes?.find((attr) => {
        const attrKeys = Object.keys(attr);
        return attrKeys[0] === 'copy_position';
    });

    const copyPosition = containerAttributePosition?.copy_position || {};
    const hasCopyPositionValues = ((Object.values(copyPosition)).filter((elem) => {
        if (typeof elem === 'string') {
            return elem.length > 0;
        }
        return false;
    })).length > 0;

    const svgPositionStyles = container_attributes?.find((attr) => {
        const attrKeys = Object.keys(attr);
        return attrKeys[0] === 'svg_position';
    });

    useEffect(() => {
        setShowSkeleton(false);
    }, []);

    const classes = useStyles({
        message_location,
        blockMargin,
        copyPosition,
        height,
        presentation_type,
        image,
    });

    if (!linking && !message_styling && !message_collection) {
        return null;
    }

    if (!isMobile && (!image || !image?.url || !linking?.link)) {
        return null;
    }
    if (isMobile && (!image_mobile || !image_mobile?.url || !linking?.link)) {
        return null;
    }
    const panelData = {
        message_collection,
        overlays,
        svgPositionStyles: svgPositionStyles?.svg_position,
    };

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse,
        isFireImpression: !!salesforceResponse?.content,
        page: { type: 'home' },
    });

    const dynamicId = `personalized-hero-${uid}`;

    return (
        <div id={dynamicId}>
            <Link
                onClick={(e) => {
                    trackClickThrough();
                    ifExternalUrl(e, linking?.link?.href, true);
                }}
                to={linking?.link?.href}
                tabIndex="-1"
                title={linking?.link?.title}
                className={presentationFamily === 'flower' ? `${classes.root} ${classes.tabletBanner}` : classes.root}
            >
                <div className={classes.imageContainer}>
                    <ResponsiveImage
                        path={isMobile ? image_mobile?.url : image?.url}
                        className={`${classes.root} ${classes.top}`}
                        style={{
                            height,
                            objectFit: 'cover',
                        }}
                    />
                </div>
                <div className={`${hasCopyPositionValues ? classes.contentSectionWrapperCopyPosition : classes.contentSectionWrapper}`}>
                    <div className={`${hasCopyPositionValues ? classes.contentSectionCopyPosition : classes.contentSection}`} style={position(message_location)} data-testid="hero-single-desktop">
                        <Panel2
                            data={panelData}
                            styleData={message_styling}
                        />
                    </div>
                </div>
            </Link>
        </div>

    );
};

PersonalizedHero.propTypes = {
    data: {
        use_personalized_transition: bool,
        message_location: string,
        presentation_type: string,
        height: number,
        blockMargin: shape({
            top: number,
            bottom: number,
        }),
        reference: arrayOf(shape({
            linking: shape({
                link: shape({
                    href: string,
                    title: string,
                }),
            }),
            message_collection: array,
            overlays: array,
            message_styling: array,
            image: shape({
                url: string,
                content_type: string,
            }),
            container_attributes: array,
        }).isRequired,
        ),
    },
    salesforceResponse: object,
    setShowSkeleton: func.isRequired,
};

PersonalizedHero.defaultProps = {
    data: {},
    salesforceResponse: {},
};

export default PersonalizedHero;
