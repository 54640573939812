/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    bool, number, shape,
} from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';

import SkeletonStyles from './SkeletonStyles';
import { getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';

const useStyles = makeStyles((theme) => ({
    // Import Skeleton layout
    ...SkeletonStyles,

    root: {
        position: 'relative',
        maxWidth: '1400px',
        margin: '32px auto',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '16px',
        },
    },
    heading: {
        margin: '0 auto 20px auto',
        width: '25%',
        height: '25px',
        [theme.breakpoints.down('xs')]: {
            height: '15px',
        },
    },
    productContainer: (props) => ({
        width: '100%',
        padding: '0 50px',
        display: 'grid',
        gridTemplateColumns: `repeat(${props?.productToShow} , 1fr)`,
        [theme.breakpoints.down('xs')]: {
            padding: '0 30px',
        },
    }),
    arrows: {
        position: 'absolute',
        zIndex: '999',
        width: '30px',
        height: '30px',
        top: '50%',
        transform: 'translate(0,-50%)',
        '&.left': {
            left: 0,
        },
        '&.right': {
            right: 0,
        },
        [theme.breakpoints.down('xs')]: {
            width: '25px',
            height: '25px',
        },
    },

    // Page Elements
    productGrid: {
        '&--product-container': {
            padding: '0 15px',
        },
        '&--product-block': {
            paddingBottom: '106%',
        },
        '&--product-title': {
            width: '80%',
            height: '10px',
        },
        '&--product-price': {
            width: '50%',
            height: '10px',
        },
    },

}));

const CarouselBuilderSkeleton = ({ isTemplateProduct, nDisplayProducts, breakPoints }) => {
    const ssrDeviceType = useSelector(getSSRDeviceType);
    const isMobile = useMediaQuery(`(max-width: ${breakPoints.mobile}px)`);
    const isDesktop = useMediaQuery(`(min-width: ${breakPoints.desktop}px)`);
    const isTablet = useMediaQuery(`(min-width: ${breakPoints.mobile}px) and (max-width: ${breakPoints.desktop}px)`);

    // Product to show according to conditions
    const onDesktop = nDisplayProducts.desktop;
    const onMobile = isTemplateProduct ? 1 : nDisplayProducts.mobile;

    // eslint-disable-next-line no-nested-ternary
    let productToShow = isMobile ? onMobile : (isDesktop ? onDesktop : nDisplayProducts.tablet);

    // SSR
    if (!isMobile && !isDesktop && !isTablet) {
        productToShow = ssrDeviceType === 'mobile' ? 2 : 5;
    }

    const classes = useStyles({ productToShow });

    const renderProduct = () => {
        const productArr = [];
        for (let i = 0; i < productToShow; i += 1) {
            productArr.push((
                <div key={i} className={`${classes.productGrid}--product-container`}>
                    <div className={`${classes.productGrid}--product-block ${classes.textFW} ${classes.shimmer}`} />
                    <div className={`${classes.productGrid}--product-title ${classes.textSM} ${classes.shimmer}`} />
                    <div className={`${classes.productGrid}--product-price ${classes.textSM} ${classes.shimmer}`} />
                </div>
            ));
        }
        return productArr;
    };

    return (
        <div data-testid="CarouselBuilderSkeleton" className={classes.root}>
            <div className={`${classes.heading} ${classes.textFW} ${classes.shimmer}`} />
            <div className={classes.productContainer}>
                {ssrDeviceType !== 'mobile' && <div className={`${classes.arrows} left ${classes.shimmer}`} />}
                {ssrDeviceType !== 'mobile' && <div className={`${classes.arrows} right ${classes.shimmer}`} />}
                {renderProduct()}
            </div>
        </div>
    );
};

CarouselBuilderSkeleton.propTypes = {
    isTemplateProduct: bool,
    nDisplayProducts: shape({
        mobile: number,
        desktop: number,
        tablet: number,
    }),
    breakPoints: shape({
        mobile: number,
        desktop: number,
    }),
};

CarouselBuilderSkeleton.defaultProps = {
    isTemplateProduct: false,
    nDisplayProducts: {
        mobile: 2,
        desktop: 5,
        tablet: 3,
    },
    breakPoints: {
        mobile: 600,
        desktop: 1024,
    },
};

export default CarouselBuilderSkeleton;
