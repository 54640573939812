/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';

import { getBrandName } from '../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getIsBot, getValidatedZipcode } from '../../../state/ducks/App/App-Selectors';
import { getUserType, getProfileInfo } from '../../../state/ducks/App/ducks/Common/Common-Selectors';
import useGetAccessTokenSafely from '../../gql/hooks/useGetAccessTokenSafely';
import { GRAPHQL_ENV } from '../../gql';
import findBuyItAgainRecsSF from '../../gql/queries/findBuyItAgainRecsSF';
import useBrowserUUID from '../useBrowserUUID';
import { getIsAuthenticatedStatus } from '../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getPassportData, getPassportSubscriptionStatus } from '../../../state/ducks/Passport/Passport-Selectors';
import validatePassport from '../validatePassport';
import { getFeatureFlag } from '../../../state/ducks/App/ducks/Config/Config-Selectors';

/**
 * @description - used to communicate with customer-personalize API for personalized content.
 * @param { string } campaignFeature - salesforce campaign name
 * @param {boolean} isSympathySku -
 * @param {string} partNumber -
 * @param {object} targeting -
 * @param {string} indivId -
 * @param { object } apolloOptions - overrides | additional options to pass to useQuery API
 * @param {boolean} optInHoldoutParticipation - overrides holdout participation for specific UI/Components
 * @param {boolean} resolveIdentity - used to lookup a customers indivID when calling PZ services
 * @returns { object } - { loading, error, data, variables } -> add variables.skip to loading handling and variables.eidError to error handling
 */

const useSalesforceBuyItAgainRecsQuery = ({
    campaignFeature,
    targeting,
    filterByZipCode = false,
    apolloOptions = {},
    queryName = 'BuyItAgainRecommendations',
    optInHoldoutParticipation = false,
    resolveIdentity = false,
}) => {
    const isBot = useSelector(getIsBot);
    const isSalesForceRecsEnabled = useSelector(getFeatureFlag('is-salesforce-recs-enabled'));

    if (isBot || !isSalesForceRecsEnabled) {
        return {
            loading: false, error: false, data: null, variables: { skip: false },
        };
    }

    const query = findBuyItAgainRecsSF(queryName);
    const brand = useSelector(getBrandName) || '1800flowers';
    const interactionName = `${brand} - Get Campaign - ${campaignFeature}`;
    const validatedZipCode = useSelector(getValidatedZipcode);
    // set sessionLoginState according to user login state
    const userType = useSelector(getUserType);
    const { email } = useSelector(getProfileInfo);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const passportData = useSelector(getPassportData);
    const isPassportMember = validatePassport('isActivePassportUser', passportData);
    const isPassportAdded = useSelector(getPassportSubscriptionStatus);
    const isPersonalizationHoldoutEnabled = optInHoldoutParticipation ? useSelector(getFeatureFlag('is-personalization-holdout-enabled')) : optInHoldoutParticipation;
    let sessionCustomerType;
    if ((isPassportMember && isAuthenticated) || isPassportAdded) {
        sessionCustomerType = 'P'; // Passport members > Registered users
    } else if (isAuthenticated) {
        sessionCustomerType = 'R'; // Registered user, but not a Passport member
    } else {
        sessionCustomerType = 'G'; // Guest
    }

    const userAttributes = [];
    userAttributes.push({ name: 'currentBrand', value: brand });
    userAttributes.push({ name: 'sessionLoginState', value: userType === 'R' || userType === 'P' ? 'true' : 'false' });
    userAttributes.push({ name: 'sessionCustomerType', value: sessionCustomerType });

    const browserUUID = useBrowserUUID();

    const user = {
        anonymousId: browserUUID,
    };

    if (filterByZipCode && validatedZipCode) user['zipCode'] = validatedZipCode;
    if (email) user['emailId'] = email;

    const jwt = useGetAccessTokenSafely();
    // skip until jwt is loading
    const skip = Boolean(!jwt || !browserUUID);

    return useQuery(query, {
        variables: (skip) ? { skip } : {
            brand,
            environment: GRAPHQL_ENV,
            isHoldoutEligible: isPersonalizationHoldoutEnabled,
            resolveIdentity,
            user,
            userAttributes,
            targeting: {
                interactionName,
                ...targeting,
            },
        },
        context: { api: 'personalizedContent', jwt },
        skip,
        fetchPolicy: 'no-cache',
        ...apolloOptions,
    });
};

export default useSalesforceBuyItAgainRecsQuery;
