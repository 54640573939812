/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { object } from 'prop-types';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '0px auto',
        maxWidth: '1380px',
        color: theme.palette.black,
    },
}));

const PersonalizedWidget = (props) => {
    const classes = useStyles();
    const {
        components,
    } = props;

    const {
        GuestUserGreetingContainer,
        HeroSingle,
    } = components;

    return (
        <div className={classes.container}>
            {GuestUserGreetingContainer()}
            {HeroSingle()}
        </div>
    );
};

PersonalizedWidget.propTypes = {
    components: object.isRequired,
};

export default (PersonalizedWidget);
