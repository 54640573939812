/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';

import { object } from 'prop-types';
import { Collapse } from '@material-ui/core';
import { getUserType } from '../../../../../../state/ducks/App/ducks/Common/Common-Selectors';
import GuestUserGreetingUI from './GuestUserGreetingUI';
import { emitCustomTrackingEvent } from '../../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';

function GuestUserGreetingContainer({ cmsData, salesForceCampaignResponse }) {
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const persistedUserProfile = cookies.get('persisted_user_profile');
    const userType = useSelector(getUserType);
    const isUserEligible = userType === 'G';
    const isModuleEnabledFromCMS = cmsData?.is_module_enabled;

    useEffect(() => {
        if (isUserEligible && isModuleEnabledFromCMS) {
            dispatch(emitCustomTrackingEvent({
                action: 'Impression',
                salesforceResponse: salesForceCampaignResponse,
                page: { type: 'home' },
                eventSubType: 'campaign-tracking',
            }));
        }
    }, [isUserEligible, isModuleEnabledFromCMS]);

    const getUserName = () => {
        const { firstName } = persistedUserProfile || {};
        if (firstName?.length > 0) {
            return `${firstName[0]?.toUpperCase()}${firstName.slice(1)}`;
        }
        return '';
    };
    if (isUserEligible && isModuleEnabledFromCMS) {
        return (
            <Collapse in={isUserEligible} timeout={1500}>
                <GuestUserGreetingUI
                    userName={getUserName()}
                    blockData={cmsData}
                    salesForceCampaignResponse={salesForceCampaignResponse}
                />
            </Collapse>
        );
    }
    return null;
}

GuestUserGreetingContainer.propTypes = {
    cmsData: object.isRequired,
    salesForceCampaignResponse: object.isRequired,
};

export default GuestUserGreetingContainer;
