/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { object } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Features from '../../../UniversalTemplate/Components/Features/Features';
import Banners from '../../../UniversalTemplate/Components/Banners/Banners';
import Tiles from '../../../UniversalTemplate/Components/Tiles/Tiles';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const restructureObject = (type, obj) => {
    const data = {
        [type]: {
            reference: [obj],
        },
    };
    return data;
};

const useStyles = makeStyles(() => ({
    defaultCSS: {
        margin: '2rem auto',
        maxWidth: '1400px',
    },
    withGridSystem: {
        margin: '0 auto',
        maxWidth: '1400px',
        width: '100%',
    },
}));

const UniversalHomepageComponent = ({ data, salesforceResponse }) => {
    if (!data) return <></>;
    const blockType = () => {
        if (data.banners?.length) return  <Banners data={restructureObject('banner', data.banners[0])} salesforceResponse={salesforceResponse} />;
        if (data.tiles?.length) return  <Tiles data={restructureObject('tiles', data.tiles[0])} salesforceResponse={salesforceResponse} />;
        if (data.feature?.length) return  <Features data={restructureObject('product_feature', data.feature[0])} />;
        return <></>;
    };
    const classes = useStyles();
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));

    const universalHomepageComponentStyle = ffHasHomepageGridStructure ? classes.withGridSystem : classes.defaultCSS;

    return (
        <div className={universalHomepageComponentStyle} data-testid="homepage_universal_component">
            <div>
                <style>{'[data-pagetype="homepage"]>div[data-component="universalHomepageComponent"]'}</style>
                {blockType()}
            </div>
        </div>
    );
};

UniversalHomepageComponent.propTypes = {
    data: object.isRequired,
    salesforceResponse: object,
};

UniversalHomepageComponent.defaultProps = {
    salesforceResponse: {},
};

export default UniversalHomepageComponent;
