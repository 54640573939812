/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React from 'react';
import { useQuery } from '@apollo/client';
import {
    string, shape, arrayOf, bool,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
// components
// eslint-disable-next-line import/no-cycle
import HomePageBlock from '../../GraphqlHomePage/HomePageBlock';
// queries
import findContent from '../../../../gql/queries/findContent';

const ContentComponentContainer = ({
    variant,
    block,
    brand,
    ssrDeviceType,
    presentationFamily,
    isOptimizeTest,
}) => {
    // TODO fallback?
    // queries and contentTypes will drive the determination for content - as ab tests expand this will become more functional (cross over between different pages? e.g. UNT)
    const query = `\\"a_b_testing.unique_id\\": \\"${block?.unique_id}\\", \\"a_b_testing.variant\\": \\"${variant}\\"`;
    const contentType =  block.reference?.[0]?._content_type_uid;
    let reference = false;
    if (block?.content_reference_string) {
        // getting ref string from CS, single reference isn't resolving ref inside ref.
        // TODO:: We might need to use ref from CS, but currently only updating for hero_single to avoid any break other test running.
        reference = contentType === 'hero_single' ? block?.content_reference_string :  ['reference']; // confusing but this is referencing the "Reference" field on itself
    }

    const findContentOptions = {
        brand,
        variant,
        contentType,
        typename: '__typename',
        query,
        reference,
    };
    const FIND_CONTENT_TYPE_QUERY = findContent(findContentOptions);
    const { loading, error, data } = useQuery(FIND_CONTENT_TYPE_QUERY);

    if (loading) {
        if (isOptimizeTest) {
            return <div style={{ width: block?.skeleton?.width, height: block?.skeleton?.height, background: '#e3e3e3' }} />;
        }
        return null;
    }
    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            jsError: error,
            message: `Failed to fetch content data for: content type ${contentType}, variant ${variant} query ${query} on brand ${brand}`,
        });
    }

    if (data?.findContent?.content?.entries?.length > 0) {
        // reform the contentBlock type structure and feedback into Homepage
        let newBlock = {};
        newBlock = {
            [contentType]: {
                skeleton: block?.skeleton,
                ...data?.findContent?.content?.entries?.[0],

            },
        };
        if (contentType === 'hero_single_responsive') {
            newBlock = {
                [contentType]: {
                    skeleton: block?.skeleton,
                    reference: [{
                        ...data?.findContent?.content?.entries?.[0],
                        _content_type_uid: block.reference?.[0]?._content_type_uid,
                    }],
                },
            };
        }

        return (
            <HomePageBlock
                block={newBlock}
                ssrDeviceType={ssrDeviceType}
                presentationFamily={presentationFamily}
                brand={brand}
            />
        );
    }
    return null;
};

ContentComponentContainer.propTypes = {
    brand: string.isRequired,
    variant: string.isRequired,
    ssrDeviceType: string.isRequired,
    presentationFamily: string.isRequired,
    block: shape({
        reference: arrayOf(shape({
            uid: string.isRequired,
            _content_type_uid: string.isRequired,
        }).isRequired),
    }).isRequired,
    isOptimizeTest: bool.isRequired,
};

export default ContentComponentContainer;
