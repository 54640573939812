/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { withRouter } from 'react-router';

const styles = () => ({
    root: {
        display: 'flex',
        margin: '30px 0',
        width: '100%',
        height: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: '#48788B',
    },
    passportLogo: {
    },
    divider: {
        margin: '0 40px',
        width: '1px',
        height: '70px',
        backgroundColor: '#86C9A9',
    },
    bannerCopy: {
        maxWidth: '400px',
        fontSize: '20px',
        color: '#fff',
    },
    price: {
        margin: '0 40px',
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        backgroundColor: '#4D2A7B',
        fontSize: '23px',
        paddingTop: '34px',
        textAlign: 'center',
        fontWeight: 900,
        color: '#fff',
        transform: 'rotate(20deg)',
    },
    learnMore: {
        width: '190px',
        height: '45px',
        borderRadius: '30px',
        background: '#fff',
        color: '#48788B',
        textAlign: 'center',
        fontSize: '20px',
        paddingTop: '8px',
        fontWeight: 700,
    },
});

// TODO:
//  - Add mobile
const PassportBannerUI = ({
    classes,
    history,
    data: {
        desktop_image: {
            title,
            url,
        },
        link: {
            href,
        },
        price,
        copy,
    },
}) => {
    const goToURL = () => {
        history.push(href);
    };
    return (
        <div
            className={classes.root}
            onKeyDown={() => {}}
            tabIndex={-1}
            role="button"
            onClick={() => goToURL()}
        >
            <img className={classes.passportLogo} src={url} alt={title} />
            <div className={classes.divider} />
            <ReactMarkdown
                source={copy}
                className={classes.bannerCopy}
            />
            <div className={classes.price}>${price}</div>
            <div className={classes.learnMore}>Learn More</div>
        </div>
    );
};

PassportBannerUI.propTypes = {
    classes: object.isRequired,
    history: object.isRequired,
    data: object.isRequired,
};

export default withRouter(withStyles(styles)(PassportBannerUI));
