/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { object, string } from 'prop-types';
import ReactHTMLParser from 'react-html-parser';

import { parseFont } from '../../../../../helpers/common/helper';
import BlockHeading from '../../../../ContentTypeComponents/BlockHeading';

const useStyles = makeStyles((theme) => ({
    heading: (styles) => (
        {
            flex: '1 1 50%',
            marginBottom: '24px',
            fontFamily: parseFont(styles?.fontFamily) || 'Playfair Display',
            color: styles?.color,
            fontStyle: styles?.fontStyle,
            '& h2': {
                fontSize: styles?.fontSize || '32px',
            },
            '& span': {
                fontSize: styles?.fontSize1 || '18px',
                fontFamily: parseFont(styles?.fontFamily1) || theme?.typography?.fontFamily,
                color: styles?.color1,
                fontStyle: styles?.fontStyle1,
            },
            [theme.breakpoints.down(600)]: {
                '& h2': {
                    fontSize: styles?.fontSize || '24px',
                },
            },
        }
    ),
    headingAlignment: {
        display: 'flex',
        flex: '1 1 50%',
        '& h2': {
            margin: '0px 0px 4px 0px',
        },
    },
}));

export default function Heading({
    title, subTitle, styles,
}) {
    const classes = useStyles(styles);

    return (
        <div id="recentlyViewed-heading-id" className={classes.heading}>
            <div className={classes.headingAlignment}>
                <BlockHeading heading={ReactHTMLParser(title)} />
            </div>
            <span id="recentlyViewed-subTitle-id">{ReactHTMLParser(subTitle)}</span>
        </div>
    );
}

Heading.propTypes = {
    title: string.isRequired,
    subTitle: string.isRequired,
    styles: object,
};

Heading.defaultProps = {
    styles: {},
};
