/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object, string, shape, arrayOf,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { useUIDSeed } from 'react-uid';
import LinkOrATag from '../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const styles = (theme) => ({
    mainSocialIcon: {
        maxWidth: 1400,
        width: '100%',
        margin: '20px auto',
    },
    socialIcon: {
        display: 'flex',
        justifyContent: 'center',
    },
    socialImages: {
        display: 'flex',
    },
    socialHeading: {
        padding: '4px 15px',
        '& h1,h2,h3,h4,h5,h6,p': {
            margin: 0,
            fontWeight: 100,
            color: theme.palette.cms?.contentColor || '#666',
        },
    },
    socialLink: {
        padding: '0 10px',
        display: 'block',
    },

});

const SocialiconHomepageBlock = ({
    classes, data,
}) => {
    const sliderLinks = data?.social_links;
    const mainHeading = data?.heading;

    if (!sliderLinks) {
        return null;
    }

    // regex to match and remove text "font-family:"
    const setFontFamily = data?.font_family ? data?.font_family.replace(/font-family: |;/g, '') : null;

    const headingOverrideStyle = {
        fontFamily: setFontFamily || 'Helvetica, sans-serif',
    };

    const seed = useUIDSeed();

    return (
        <Grid container>
            <Grid item xs={12} className={classes.mainSocialIcon}>
                <div className={classes.socialIcon}>
                    <div className={classes.socialHeading} style={headingOverrideStyle}>
                        <ReactMarkdown source={mainHeading} />
                    </div>
                    <div className={classes.socialImages}>
                        {sliderLinks.map((iconData) => {
                            const withoutHoverImg = iconData?.icon?.url || '';
                            const withHoverImg = iconData?.hover_icon?.url || iconData?.icon?.url;
                            return (
                                <div key={seed(iconData)}>
                                    <LinkOrATag className={classes.socialLink} href={iconData?.link?.href}>
                                        <div>
                                            <img
                                                src={withoutHoverImg}
                                                alt={iconData?.icon?.title}
                                                onMouseOver={(e) => { e.currentTarget.src = withHoverImg; }}
                                                onFocus={(e) => { e.currentTarget.src = withHoverImg; }}
                                                onMouseOut={(e) => { e.currentTarget.src = withoutHoverImg; }}
                                                onBlur={(e) => { e.currentTarget.src = withoutHoverImg; }}
                                            />
                                        </div>
                                    </LinkOrATag>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

SocialiconHomepageBlock.propTypes = {
    classes: object.isRequired,
    data: shape({
        heading: string,
        font_family: string,
        social_links: arrayOf(shape({
            hover_icon: shape({
                url: string,
                title: string,
            }),
            icon: shape({
                url: string,
                title: string,
            }),
            link: shape({
                title: string,
                href: string,
            }),
        })),
    }),
};
SocialiconHomepageBlock.defaultProps = {
    data: {},
};
export default withStyles(styles)(SocialiconHomepageBlock);
