/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useEffect } from 'react';
import {
    object, array, func,
} from 'prop-types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import GraphqlFindGiftFast from './GraphqlFindAGiftFast';
// import GraphqlFoodGiftFinder from './GraphqlFoodGiftFinder';

const GiftFinderABTestContainer = ({
    data, flags, salesforceResponse, setShowSkeleton,
}) => {
    const ldVariant = flags?.whichGiftFinderVariant?.variant?.toLowerCase();

    useEffect(() => {
        setShowSkeleton(false);
    }, []);

    if (!ldVariant) {
        return <div style={{ width: '100%', height: '327px', background: 'linear-gradient(270deg, #F2F8FC, #E7EDF1, #F2F8FC)' }} />;
    }
    for (let i = 0; i < data.length; i += 1) {
        const cmsVariant = data?.[i].a_b_testing?.variant?.toLowerCase();
        if (ldVariant === cmsVariant) {
            return <GraphqlFindGiftFast data={data[i]} dateOptions={[]} salesforceResponse={salesforceResponse} />;
        }
    }

    return null;
};

GiftFinderABTestContainer.propTypes = {
    data: array.isRequired,
    flags: object.isRequired,
    salesforceResponse: object,
    setShowSkeleton: func.isRequired,
};

GiftFinderABTestContainer.defaultProps = {
    salesforceResponse: {},
};

export default withLDConsumer()(GiftFinderABTestContainer);
