/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpLogger from 'mbp-logger';

export default function isEmptyReference(blockObj = '', blockKey = '', component = 'Unkown Component') {
    /* @intention: Run a check to see that a block's references are deployed to the same environment.
     *             If a reference has no length then it is likely that it has not been deployed completely.
     * @param {obj} - blockObj
     * @param {string} - blockKey
     * @param {string} - component e.g. HomePageBlock.js
     * @return: {bool} - true = the reference is empty
     */
    if (blockObj?.reference && !blockObj.reference?.length) {
        mbpLogger.logError({
            function: component,
            message: `Missing reference within block type "${blockKey}" in ${component}.
        Please ensure all associated references in ${blockKey} have been deployed to the desired environments`,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
        });
        return true;
    }
    return false;
}
