/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';
import { GRAPHQL_ENV } from '..';

/**
 * @param {string} brand - domain name: brand['domain-name']
 * @param {string} uid - uid: (the UID of the entry in cms)
 * @param {string} contentType - contentType: _content_type_uid (the content type ID)'
 */

const findContentByUID = ({ brand, uid, contentType }) => (
    gql`{ 
            findContentByUID(brand: "${brand}", environment: "${GRAPHQL_ENV}",  locale: "en-us", contentType: "${contentType}", uid: "${uid}"){
            content
        }}`
);

export default findContentByUID;
